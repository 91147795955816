<template>
  <article
    v-loading="loading"
    :element-loading-text="loadingText"
    class="work-detail"
    @click="articleClick"
  >
    <RouterNav :name="name"></RouterNav>
    <header :class="['header', headerShow ? 'h-132' : 'h-0']">
      <div class="flex-grow" id="aa">
        <div class="flex">
          <div class="left">
            <div class="name" v-html="name"></div>
            <div :class="['nowrap', status === '0' ? 'theme-color' : status === '1' ? 'col-1DC060' : 'col-999']">
              【{{ status === '0' ? '未开始' : status === '1' ? '进行中' : '已结束' }}】
            </div>
          </div>
          <div class="right">
            <el-popover width="80" class="flex" trigger="hover" v-model="batchShow">
              <div class="batch">
                <div class="batch-list" @click="batchDialog(1)">批量催交</div>
                <div class="batch-list" @click="batchReviewDialog">批量点评</div>
                <div class="batch-list" @click="batchDialog(3)">批量打回</div>
              </div>
              <div class="btn handle-btn" slot="reference">
                <div>批量操作</div>
                <!--<div class="icon"></div>-->
              </div>
            </el-popover>
            <div class="btn mr-15" @click="setDialogChange">作业设置</div>
            <div class="btn">
              <div class="link excel" @click="exportExcel(itemId)">
                <div class="icon"></div>
                <div>导出当前成绩</div>
              </div>
              <div class="line" v-if="offline === 1">|</div>
              <div class="link zip" @click="offlineDownload" v-if="offline === 1">
                <div class="icon"></div>
                <div>导出讲评包</div>
              </div>
            </div>
          </div>
        </div>
        <div class="info">
          <span class="mr-28">{{ clazzName }}</span><span class="mr-28">{{ count }}/{{ userList.length }}人</span><span>{{ score ? score.toFixed(1) : 0 }}/{{ totalScore }}分</span>
        </div>
        <div class="flex-end">
          <div>
            <div v-if="status === '0'" class="date mb-10">开始时间：{{ dateStart | trimT }}</div>
            <div v-if="['1', '2'].includes(status)" class="date">结束时间：{{ dateEnd | trimT }}</div>
          </div>
          <div>
            <!--TODO 不及格人数字段不明-->
            <span>本次作业：</span>
            <span class="num">{{ unfinishNameList.length }}</span>
            <span>人未完成</span>
            <span class="num">{{ failNameList.length }}</span>
            <span>人不及格</span>
            <span class="check-btn" @click="checkUnfinish">查看名单</span>
          </div>
        </div>
      </div>
    </header>
    <section class="container">
      <div class="menu">
        <div :class="['list', menuIndex === index ? 'active' : '']" v-for="(item, index) in menuList" :key="item.id" @click="menuChange(index)">
          <div>{{ item.name }}</div>
          <div class="line"></div>
        </div>
      </div>
      <div ref="content" :class="['content', headerShow ? '' : 'h-212']">
        <!--学生情况-->
        <div v-show="menuIndex == 0" class="student-list">
          <UserList
            :itemId="itemId"
            :paperGroupId="paperGroupId"
            :category="category"
            :name="name"
            :status="status"
            :flag="flag"
            :list="userList"
            :initQuestionTypes="initQuestionTypes"
            :sortType="sortType"
            :sortBy="sortBy"
            :passageType="passageType"
            :expireRedo="expireRedo"
            :answerTimes="answerTimes"
            :height="headerShow ? '550px' : '700px'"
            @sort="sort"
            @onScrollTop="handleScroll"
          />
        </div>
        <!--班级报告-->
        <div v-show="menuIndex == 1">
          <ClassExamDetail
            ref="classExamDetail"
            :item-id="itemId"
            :flag="flag"
            :passageType="passageType"
          />
        </div>
        <!--作业内容-->
        <div v-show="menuIndex == 2">
          <iframe ref="iframes" :class="['iframe', headerShow ? '' : 'h-188']" :src="iframeSrc" frameborder="0" v-if="menuIndex == 2 && [5, 9, 24].includes(flag)"></iframe>
          <Alphabet :list="alphabetList" :itemId="itemId" :flag="flag" :clazzId="clazzId" v-if="flag === 2 && alphabetList.length > 0"></Alphabet>
          <Word :list="wordList" :typeList="typeList" :name="name" :id="itemId" :flag="flag" v-else-if="flag === 7 && wordList.length > 0"></Word>
          <div v-else-if="flag === 3">
            <Funny :repeatAfterData="repeatAfterData" :itemId="itemId" :flag="flag" v-if="repeatAfterData && passageType === '2'"></Funny>
            <RepeatAfter :data="repeatAfterData" :itemId="itemId" :flag="flag" v-else-if="repeatAfterData"></RepeatAfter>
          </div>
          <Written :data="writtenData" v-else-if="flag === 4 && writtenData"></Written>
          <div v-else-if="flag === 8" class="dub-hoemwork-detail">
            <video v-if="dubDeatil.videoUrl" :src="dubDeatil.videoUrl" controls="controls" width="1044">
              您的浏览器不支持 video 标签。
            </video>
            <div class="hoemwork-info">
              <div class="dub-sentence">
                <div v-for="sentence in dubDeatil.sentences" :key="sentence.id" class="sentence-item">
                  <div class="sentence-original">{{ sentence.originalText }}</div>
                  <div class="sentence-translated">{{ sentence.translatedText }}</div>
                </div>
              </div>
              <div class="dub-response-records">
                <div class="record-header">
                  <div class="row-sn">序号</div>
                  <div class="row-student">学生</div>
                  <div class="row-score">得分</div>
                </div>
                <div v-for="(record, index) in dubResponseRecords" :key="record.id" class="record-row">
                  <div class="row-sn">{{ index + 1}}</div>
                  <div class="row-student">{{ record.userName }}</div>
                  <div class="row-score">
                    <span :class="['row-score-value', common.getScoreClassName(record.score, record.totalScore)]">{{ formatScore(record.score) || 0 }}</span>
                    <img class="row-btn-play" src="../../../assets/play.png" alt="" width="20" height="20" @click="toDubDetail(record)">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 查看名单 -->
    <Dialog
      :dialogType="dialogType"
      :dialogFlag="dialogFlag"
      :num="unfinishNameList.length"
      :unfinishContent="unfinishContent"
      :unfinishIds="unfinishIds"
      :list="dialogList"
      :visible="dialogVisible"
      @handleClose="handleClose"
      @batchUrge="batchUrge"
      @batchRedo="batchRedo"
    ></Dialog>

    <BatchReviewDialog
        ref="BatchReviewDialog"
        :visible="batchReviewDialogVisible"
        :id="itemId"
        @userRefresh="userRefresh"
        @batchReviewClose="batchReviewClose"
    ></BatchReviewDialog>

    <SetDialog
      :visible="setDialogVisible"
      :expireRedos="expireRedo"
      :answerTimess="answerTimes"
      :toPublics="toPublic"
      :publicTimes="publicTime"
      @confirmSet="confirmSet"
      @handleClose="setDialogChange"
    ></SetDialog>
    <!-- 学生配音作答详情 -->
    <DubDialog ref="dubDialogRef" :dubDetail="sutdentDubDetail" />
  </article>
</template>

<script>
import {
  getBaseExerciseDetailForApp,
  getHomeworkDetails2dub,
  getVideoAnswerByStudent,
  sentHomeworkItemNotice
} from '@/api/homework.js'
import RouterNav from '@/components/RouterNav.vue'
import { readFile } from '@/utils/service.js'
import ClassExamDetail from '@/views/homework/check/components/classWorkDetail'
import Decimal from 'decimal.js'
import _ from 'lodash'
import Funny from '../components/Funny.vue'
import Alphabet from './components/Alphabet.vue'
import BatchReviewDialog from './components/BatchReviewDialog.vue'
import Dialog from './components/Dialog.vue'
import DubDialog from './components/DubDialog.vue'
import RepeatAfter from './components/RepeatAfter.vue'
import SetDialog from './components/SetDialog.vue'
import UserList from './components/UserList.vue'
import Word from './components/Word.vue'
import Written from './components/Written.vue'
import { getUUID } from '@/utils/local'

export default {
  name: 'workDetail',
  components: {
    UserList,
    Alphabet,
    Word,
    RepeatAfter,
    Funny,
    Written,
    Dialog,
    RouterNav,
    ClassExamDetail,
    BatchReviewDialog,
    SetDialog,
    DubDialog
  },
  data() {
    return {
      loading: false,
      loadingText: '',
      name: '',
      bagId: '',
      itemId: '',
      offline: 2,
      clazzId: '',
      category: '',
      paperGroupId: '',
      status: '1',
      passageType: '1', // 1：课文听读2:趣味配音
      clazzName: '',
      count: '',
      score: '',
      totalScore: '',
      dateStart: '',
      dateEnd: '',
      userList: [],
      initQuestionTypes: [], // 听说题组的题型详情 - header
      unfinishContent: '',
      unfinishIds: '',
      menuList: [{ id: 1, name: '学生情况' }, { id: 2, name: '班级报告' }, { id: 3, name: '作业内容' }],
      menuIndex: 0,
      flag: '', // 1单词 2音标 3跟读 4笔试 5题组 6拓展作业 7新单词 8配音
      alphabetList: [],
      wordList: [],
      typeList: [],
      repeatAfterData: null,
      writtenData: null,
      dialogVisible: false,
      dialogType: 2,
      dialogFlag: 1, // 1:批量催交，2：批量点评，3：批量打回
      dialogList: [],
      batchShow: false, // 批量操作状态控制
      sortType: 'desc',
      sortBy: 'activeRate',
      headerShow: true,
      prev: 0,
      batchReviewDialogVisible: false,
      setDialogVisible: false,
      expireRedo: 2,
      answerTimes: 1,
      toPublic: 1,
      publicTime: '',
      unfinishNameList: [],
      failNameList: [],
      dubDeatil: {},
      dubResponseRecords: {},
      sutdentDubDetail: {}
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  created() {
    const {query} = this.$route
    this.itemId = query.itemId
    this.name = query.name
    this.clazzId = query.clazzId
    this.bagId = query.bagId || ''
    let {passageType} = query
    if (typeof passageType === 'number') passageType += ''
    this.passageType = passageType
    this.homeworkDetail()
  },
  mounted() {
    // this.$refs.content.addEventListener('scroll', this.handleScroll)
    // window.addEventListener('message', (e) => {
    //   this.handleScroll(e.data)
    // })
  },
  methods: {
    // 得分保留以为小数
    formatScore(num) {
      return new Decimal(num).toFixed(1, Decimal.ROUND_HALF_UP)
    },
    // 子作业详情
    homeworkDetail() {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.itemDetail,
        headers: { 'Content-Type': 'application/json' },
        data: {
          itemId: this.itemId,
          needQuestionType: true // 是否返回题型分数详情
        }
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          const {data} = response
          // offline 用于标识是不是广东的，广东的多一个导出奖品包的功能
          // clazzId 班级id
          // category 详情类型 1：作业；2：考试；
          // status 状态 0:未开始;1:进行中；2:已结束；
          // clazzName 班级名称
          // count 完成作业学生人数
          // score 平均分
          // totalScore 总分
          // dateStart 开始时间
          // dateEnd 结束时间
          // paperGroupId
          // expireRedo 到期重做
          // answerTimes 回答时间
          // toPublic 答案公布
          // publicTime 答案公布时间
          // falg 作业类型 1单词 2音标 3跟读 4笔试 5题组 6拓展作业 7新单词
          this.offline = data.offline
          this.clazzId = data.clazzId
          this.category = data.category
          this.status = data.status
          this.clazzName = data.clazzName
          this.count = data.count
          this.score = data.score
          this.totalScore = data.totalScore
          this.dateStart = data.dateStart
          this.dateEnd = data.dateEnd.replace(/T/g, ' ')
          this.paperGroupId = data.paperGroupId
          this.expireRedo = data.expireRedo
          this.answerTimes = data.answerTimes
          this.toPublic = data.toPublic
          this.initQuestionTypes = data.initQuestionTypes
          if (data.publicTime) this.publicTime = data.publicTime.replace(/T/g, ' ')
          // const {userList} = data
          const {homeworkItemStudentList} = data
          let idsArr = []
          const {totalScore} = data
          let expireNames = []
          let noExpireNames = []
          this.unfinishNameList = []
          this.failNameList = []
          for (let i = 0, len = homeworkItemStudentList.length; i < len; i += 1) {
            if (homeworkItemStudentList[i].finishRate > 0 && homeworkItemStudentList[i].indexScoreRate < 60) {
              this.failNameList.push(homeworkItemStudentList[i].realName)
            }
            if (homeworkItemStudentList[i].finishRate !== 100) {
              this.unfinishNameList.push(homeworkItemStudentList[i].realName)
              idsArr.push(homeworkItemStudentList[i].id)
            }
            const {scores} = homeworkItemStudentList[i]
          }
          this.unfinishIds = idsArr.join() // 未完成学生id
          this.unfinishContent = `作业名称：${this.name}\n作业班级：${this.clazzName}\n截止时间：${this.dateEnd}`
          if (this.unfinishNameList.length > 0) {
            this.unfinishContent += `\n未完成名单如下：\n${this.unfinishNameList.join('、')}`
          }
          if (this.failNameList.length > 0) {
            this.unfinishContent += `\n不及格名单如下：\n${this.failNameList.join('、')}`
          }
          this.userList = homeworkItemStudentList
          const {flag} = data
          if (flag === 5) {
            const {paperGroupId} = data
            this.iframeSrc = `${this.$urls.iframeSrc}?groupId=${paperGroupId}&itemId=${this.itemId}&classId=${this.clazzId}&category=${this.category}`
          } else if (flag === 9) {
            const userId = this.userInfo.id
            const sessionId = sessionStorage.getItem('sessionId')
            const udid = getUUID()
            this.iframeSrc = `${this.$urls.homeworkDetails2wordSrc}?itemId=${this.itemId}&clazzId=${this.clazzId}&userId=${userId}&udid=${udid}&authToken=${sessionId}&apps=2&thirdtypeid=1`
          } else if (flag === 24) {
            const userId = this.userInfo.id
            const sessionId = sessionStorage.getItem('sessionId')
            const udid = getUUID()
            this.iframeSrc = `${this.$urls.homeworkDetails2textSrc}?itemId=${this.itemId}&clazzId=${this.clazzId}&userId=${userId}&udid=${udid}&authToken=${sessionId}&apps=2&thirdtypeid=1`
          }
          if (flag === 7) {
            const {typeList} = data
            this.typeList = typeList.split(',')
          }
          this.flag = flag
        }
      })
          .catch(() => {
            this.loading = false
          })
    },
    // 作业窗口显隐切换
    setDialogChange() {
      this.setDialogVisible = !this.setDialogVisible
    },
    // 确定作业设置
    confirmSet(expireRedo, answerTimes, toPublic, publicTime) {
      this.loading = true
      const dataParams = {
        itemId: this.itemId,
        dateEnd: this.dateEnd,
        expireRedo,
        answerTimes,
        toPublic
      }
      if (toPublic === 2) {
        const time = this.common.binarySystem(publicTime)
        dataParams.publicTime = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(time))
      }
      this.$axios({
        method: 'post',
        url: this.$urls.homeworkUpdateItem,
        data: dataParams
      })
          .then((response) => {
            this.loading = false
            if (response.state === '11') {
              this.$message({
                type: 'success',
                message: '修改成功'
              })
              this.setDialogVisible = false
              this.homeworkDetail()
            }
          })
          .catch(() => {
            this.loading = false
          })
    },
    // 批量点评
    userRefresh(list) {
      const {userList} = this
      for (let i = 0, len = list.length; i < len; i += 1) {
        for (let j = 0, len = userList.length; j < len; j += 1) {
          if (userList[j].id === list[i]) {
            userList[j].hasReview = '1'
            break
          }
        }
      }
      this.$refs.BatchReviewDialog.refresh(userList)
    },
    articleClick() {
      const {userList} = this
      for (let i = 0, len = userList.length; i < len; i += 1) {
        this.$set(this.userList[i], 'answerShow', false)
        this.$set(this.userList[i], 'popoverShow', false)
      }
    },
    // 监听滚动事件
    handleScroll(data) {
      let scrollTops
      if (typeof data === 'number') {
        scrollTops = data
      } else {
        scrollTops = this.$refs.content?.scrollTop
      }
      const up = scrollTops - this.prev < 0
      this.prev = scrollTops
      if (this.headerShow && !up && scrollTops > 150) {
        this.headerShow = false
      } else if (!this.headerShow && up && scrollTops === 0) {
        this.headerShow = true
      }
    },
    // 关闭批量点评弹框
    batchReviewClose() {
      this.batchReviewDialogVisible = false
    },
    // 打开批量点评
    batchReviewDialog() {
      this.batchShow = false
      this.batchReviewDialogVisible = !this.batchReviewDialogVisible
      this.$refs.BatchReviewDialog.getReviewList(this.userList)
    },
    // 重做
    batchRedo(userIds) {
      if (this.operateIntercept(this.dialogFlag)) return
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.batchRedo,
        data: {
          userIds,
          itemId: this.itemId
        }
      })
          .then((response) => {
            this.loading = false
            if (response.state === '11') {
              this.dialogVisible = false
              this.$message({
                message: '批量打回成功',
                type: 'success'
              })
            }
          })
          .catch(() => {
            this.loading = false
          })
    },
    // 催交
    batchUrge(userIds) {
      if (this.operateIntercept(1)) return
      this.loading = true
      // this.$axios({
      //   method: 'post',
      //   url: this.$urls.batchUrge,
      //   data: {
      //     userIds
      //   }
      // })
      const params = {
        teacherId: this.userInfo.teacherId,
        teacherName: this.userInfo.teacherName,
        studentIds: userIds.split(','),
        bagId: this.bagId,
        itemName: this.name,
        noticeCategory: 108
      }
      sentHomeworkItemNotice(params).then((response) => {
            this.loading = false
            if (response.state === '11') {
              this.dialogVisible = false
              this.$message({
                message: '批量催交成功',
                type: 'success'
              })
            }
          })
          .catch(() => {
            this.loading = false
          })
    },
    // 批量催交/打回
    batchDialog(flag) {
      if (this.operateIntercept(flag)) return
      this.dialogType = 3
      this.dialogFlag = flag
      const {userList} = this
      let arr = []
      if (flag === 3) {
        for (let i = 0, len = userList.length; i < len; i += 1) {
          if (userList[i].finishRate === 100) {
            arr.push(userList[i])
          }
        }
        if (arr.length === 0) {
          this.$message('当前作业未有完成学生！')
          return
        }
      } else if (flag === 1) {
        for (let i = 0, len = userList.length; i < len; i += 1) {
          if (userList[i].finishRate !== 100) {
            arr.push(userList[i])
          }
        }
        if (arr.length === 0) {
          this.$message('学生都已完成作业，无需催交')
          return
        }
      } else {
        arr = [...userList]
      }
      this.dialogList = arr
      this.dialogVisible = true
    },
    // 拦截判断 type: 1：催交；3：打回；
    operateIntercept(flag) {
      let bol = false
      // 允许重复作答+允许过期补交
      if (this.answerTimes === 1 && this.expireRedo === 1) {
        //  允许重复作答+不允许过期补交
      } else if (this.answerTimes === 1 && this.expireRedo === 2) {
        if (this.status === '2' || this.status === 2) {
          bol = true
          this.$alert('当前作业已结束且不允许补交，请修改设置后再进行此操作。', '提示', {
            confirmButtonText: '好的'
          })
        }
        //  不允许重复作答+允许过期补交
      } else if (this.answerTimes === 2 && this.expireRedo === 1) {
        if (flag === 3) {
          bol = true
          this.$alert('当前作业不允许重复作答，请修改设置后再进行此操作。', '提示', {
            confirmButtonText: '好的'
          })
        }
        //  不允许重复作答+不允许过期补交
      } else if (this.answerTimes === 2 && this.expireRedo === 2) {
        if (flag === 1) {
          if (this.status === '2') {
            bol = true
            this.$alert('当前作业已结束且不允许补交，请修改设置后再进行此操作。', '提示', {
              confirmButtonText: '好的'
            })
          }
        }
        if (flag === 3) {
          bol = true
          this.$alert('当前作业不允许重复作答，请修改设置后再进行此操作。', '提示', {
            confirmButtonText: '好的'
          })
        }
      }
      return bol
    },
    // 导出讲评包
    offlineDownload() {
      this.loading = true
      this.loadingText = '拼命加载中'
      const {itemId} = this
      this.$axios({
        method: 'get',
        url: this.$urls.offlineDownload,
        responseType: 'blob',
        timeout: 120000,
        params: {
          itemId: itemId
        }
      })
          .then((response) => {
            this.loading = false
            this.loadingText = ''
            const url = window.URL.createObjectURL(new Blob([response.data]))
            let fileName = response.headers.filename
            if (!fileName) {
              fileName = `${new Date().getTime()}.zip`
            } else {
              fileName = decodeURI(escape(fileName))
            }
            const link = document.createElement('a')
            link.download = fileName
            link.style.display = 'none'
            link.href = url
            document.body.appendChild(link)
            link.click()
            URL.revokeObjectURL(link.href)
            document.removeChild(link)
          })
          .catch((response) => {
            this.loading = false
            this.loadingText = ''
          })
    },
    // 导出当前成绩
    exportExcel(itemId) {
      const that = this
      const h = this.$createElement
      let checked = false
      const random = Math.random()
      const provinceId = sessionStorage.getItem('provinceId')
      const section = sessionStorage.getItem('schoolSection')
      this.$confirm('确认导出成绩吗？', '提示', {
        message: h('div', null, [
          h('p', null, '确认导出成绩吗？'),
          (provinceId === '440000' && section === '2' ? h('ElCheckbox', {
            style: 'position: absolute;left: 6px;bottom: -30px;',
            key: random,
            props: {
              checked: checked
            },
            on: {
              change(val) {
                checked = val
              }
            }
          }, '导出转换为20分制') : '')
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const convertScore = checked ? 1 : 0
        checked = false
        that.$axios({
          method: 'post',
          url: that.$urls.exportItemScope,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            itemId: that.itemId,
            convertScore
          }
        }).then((response) => {
          that.loading = false
          if (response.state === '11') {
            that.$message.success('下载任务已提交，请前往下载中心查看进度')
          }
        }).catch(() => {
          that.loading = false
        })
        // window.open(`${that.$urls.exportScore}?itemId=${itemId}`)
      })
      .catch((err) => {
        checked = false
        throw err
      })
    },
    // 关闭弹框
    handleClose() {
      this.dialogVisible = false
    },
    // 打开名单弹框
    checkUnfinish() {
      this.dialogType = 2
      this.dialogVisible = true
    },
    // tab切换
    menuChange(index) {
      this.menuIndex = index
      this.prev = 0
      if (index === 1) {
        const {offsetWidth} = this.$refs.content
        this.$refs.classExamDetail.showCharts(offsetWidth)
      } else if (index === 2) {
        const {flag} = this
        if (flag === 2 && this.alphabetList.length === 0) {
          this.getPaper()
        } else if (flag === 7 && this.wordList.length === 0) {
          this.getWordList()
        } else if (flag === 3 && !this.repeatAfterData) {
          this.getRepeatAfterData()
        } else if (flag === 4) {
          this.getWriteDetailNew()
        } else if (flag === 8) {
          this.fetchBaseExerciseDetailForApp()
          this.fetchHomeworkDetails2dub()
        }
      }
    },
    // 1单词 2音标 3跟读 4笔试 5题组 6拓展作业 7新单词
    // 精读
    getRepeatAfterData() {
      this.loading = true
      let url = this.$urls.repeatDetail
      if (this.passageType === '2') {
        url = this.$urls.getFunVoiceDetail
      }
      this.$axios({
        method: 'post',
        url,
        data: {
          itemId: this.itemId,
          flag: this.flag,
          id: this.paperGroupId
        }
      })
          .then((response) => {
            this.loading = false
            if (response.state === '11') {
              this.repeatAfterData = response.data
            }
          })
          .catch(() => {
            this.loading = false
          })
    },
    // 我的布置
    getWriteDetailNew() {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.getWriteDetailNew,
        data: {
          itemId: this.itemId,
          classId: this.clazzId
        }
      })
          .then((response) => {
            this.loading = false
            if (response.state === '11') {
              const {data} = response
              const {titleList} = data
              let index = 0
              for (let i = 0, len = titleList.length; i < len; i += 1) {
                const {writtenList} = titleList[i]
                for (let j = 0, len = writtenList.length; j < len; j += 1) {
                  const {libList} = writtenList[j]
                  const {type} = writtenList[j]
                  let {content} = writtenList[j]
                  if (['2', '7'].includes(type)) {
                    const reg2 = /<t>.*?<\/t>/g
                    let i = 0
                    content = content.replace(reg2, (text) => { // eslint-disable-line
                      let word = text.replace(/(<t>)|(<\/t>)/g, '')
                      const pattern = /<\/?.+?\/?>/g
                      const arr = word.match(pattern)
                      word = index + 1 + i
                      const newText = `<t>${arr ? arr[0] : ''}${word}</t>`
                      i += 1
                      return newText
                    })
                    writtenList[j].content = content
                  } else if (type === '4') {
                    const pattern = /^[0-9]+.?[0-9]*$/
                    const arr = content.split('.')
                    if (pattern.test(arr[0])) {
                      arr[0] = index + 1
                    } else {
                      arr[0] = `${index + 1}.${arr[0]}`
                    }
                    writtenList[j].content = arr.join('.')
                  }
                  for (let k = 0, len = libList.length; k < len; k += 1) {
                    libList[k].index = index
                    const {titleType} = libList[k]
                    const {answerType} = libList[k]
                    const pattern = /^[0-9]+.?[0-9]*$/
                    if (titleType) {
                      const arr = titleType.text.split('.')
                      if (pattern.test(arr[0])) {
                        arr[0] = index + 1
                      } else {
                        arr[0] = `${index + 1}.${arr[0]}`
                      }
                      titleType.text = arr.join('.')
                    } else if (answerType) {
                      const arr = answerType.text.split('.')
                      if (pattern.test(arr[0])) {
                        arr[0] = index + 1
                      } else {
                        arr[0] = `${index + 1}.${arr[0]}`
                      }
                      answerType.text = arr.join('.')
                    }
                    index += 1
                  }
                  const {statisticsAnswerInfo} = writtenList[j]
                  if (statisticsAnswerInfo.statisticsMap) {
                    const {statisticsMap} = statisticsAnswerInfo
                    Object.keys(statisticsMap).forEach((key) => {
                      const item = statisticsMap[key]
                      const notDonePleLength = item.notDonePle ? item.notDonePle.length : 0
                      const donePleLength = item.donePle ? item.donePle.length : 0
                      statisticsMap[key].allPleLength = notDonePleLength + donePleLength
                    })
                  } else {
                    const notDonePleLength = statisticsAnswerInfo.notDonePle ? statisticsAnswerInfo.notDonePle.length : 0
                    const donePleLength = statisticsAnswerInfo.donePle ? statisticsAnswerInfo.donePle.length : 0
                    statisticsAnswerInfo.allPleLength = notDonePleLength + donePleLength
                  }
                }
              }
              this.writtenData = data
            }
          })
          .catch(() => {
            this.loading = false
          })
    },
    // 获取单词作业
    getWordList() {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.homeworkWordList,
        data: {
          itemId: this.itemId,
          flag: this.flag
        }
      })
          .then((response) => {
            this.loading = false
            if (response.state === '11') {
              this.wordList = response.data
            }
          })
          .catch(() => {
            this.loading = false
          })
    },
    // 获取作业详情
    getPaper() {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.paperQuestions,
        data: {
          itemId: this.itemId,
          flag: this.flag
        }
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          this.alphabetList = response.data
        }
      })
          .catch(() => {
            this.loading = false
          })
    },
    // 根据趣味配音id查询趣味配音试题详情
    fetchBaseExerciseDetailForApp() {
      this.dubDeatil = {}
      const paramData = {
        materialId: this.paperGroupId
      }
      getBaseExerciseDetailForApp(paramData).then(response => {
        if (response.state === '11') {
          this.dubDeatil = response.data
        }
      })
    },
    // 获取班级全部学生作答详情
    fetchHomeworkDetails2dub() {
      this.dubResponseRecords = []
      const paramData = {
        itemId: this.itemId,
        videoPracticeType: 3,
        classId: this.clazzId
      }
      getHomeworkDetails2dub(paramData).then(response => {
        if (response.state === '11') {
          let arr = response.data || []
          this.dubResponseRecords = _.sortBy(arr, 'score', 'asc')
        }
      })
    },
    // 获取学生新趣味配音作业作答详情
    fetchVideoAnswerByStudent(record) {
      const paramData = {
        itemId: this.itemId,
        videoPracticeType: 3,
        userId: record.userId
      }
      this.loading = true
      getVideoAnswerByStudent(paramData).then(async response => {
        setTimeout(() => {
          if (this.loading) this.loading = false
        }, 10000)
        if (response.state === '11') {
          let map = {}
          this.dubDeatil.sentences.forEach(el => {
            map[el.id] = el
          })
          let obj = response.data || {}
          obj.userName = record.userName
          obj.videoUrl = this.dubDeatil.videoUrl
          obj.videoAudioUrl = this.dubDeatil.videoAudioUrl
          let accuracy = 0
          let overall = 0
          let integrity = 0
          if (obj.infoUrl) {
            // ......
            obj.info = await readFile(obj.infoUrl)
            const length = obj.info.sentenceLibs.length
            obj.info.sentenceLibs.forEach(el => {
              el.beginTime = map[el.sentenceId].sectionBeginTime
              el.endTime = map[el.sentenceId].sectionEndTime
              el.translatedText = map[el.sentenceId].translatedText
              el.overall = el.evaluatingInfo.result.overall
              el.text = this.common.wordMate(el.evaluatingInfo.params.request.refText, el.evaluatingInfo.result.details)
              accuracy = new Decimal(accuracy).add(new Decimal(el.evaluatingInfo.result.accuracy))
              overall = new Decimal(overall).add(new Decimal(el.evaluatingInfo.result.fluency.overall))
              integrity = new Decimal(integrity).add(new Decimal(el.evaluatingInfo.result.integrity))
            })
            accuracy = Number(this.formatNum(new Decimal(accuracy).div(new Decimal(length)), 1))
            overall = Number(this.formatNum(new Decimal(overall).div(new Decimal(length)), 1))
            integrity = Number(this.formatNum(new Decimal(integrity).div(new Decimal(length)), 1))
          }
          obj.accuracy = accuracy
          obj.overall = overall
          obj.integrity = integrity
          this.sutdentDubDetail = obj
          this.loading = false
          this.$refs.dubDialogRef.changeVisible(true)
        }
      }).catch(() => {

        this.loading = false
      })
    },
    // 查看学生作答详情
    toDubDetail(record) {
      this.fetchVideoAnswerByStudent(record)
    },
    formatNum(num, precision) {
      return new Decimal(num).toFixed(precision, Decimal.ROUND_HALF_UP)
    },
    sort(name) { // 排序
      const {sortType} = this
      const {sortBy} = this
      if (sortBy !== name) {
        this.sortBy = name
        this.sortType = 'desc'
      } else {
        this.sortType = sortType === 'desc' ? 'asc' : 'desc'
      }
      const {userList} = this
      userList.sort((a, b) => {
        return this.sortType === 'asc' ? a[name] - b[name] : b[name] - a[name]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  @include flex;
  background-color: #fff;
  //height: 162px;
  padding: 0 28px;
  margin-bottom: 10px;
  overflow: hidden;
  transition: height .5s;
  height: 132px;

  .flex-grow {
    flex-grow: 1;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .flex {
    @include flex(space-between);
    margin-bottom: 4px;

    .left {
      @include flex;
      font-size: 15px;

      .name {
        max-width: 525px;
        font-size: 20px;
        font-weight: bold;
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .right {
      @include flex;

      .line {
        margin: 0 15px;
      }
    }

    .btn {
      @include flex;
      height: 34px;
      padding: 0 20px;
      border: 1px solid #E5E5E5;
      border-radius: 17px;
      font-size: 15px;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background-color: #F6F6F6;
      }
    }

    .handle-btn {
      margin: 0 14px;

      .icon {
        @include icon(url("../../../assets/icon-1.png"), 6px, 6px);
        margin-left: 4px;
        margin-top: 10px;
      }
    }

    .link {
      display: flex;
      align-items: center;

      .icon {
        @include icon(url("../../../assets/excel.png"), 26px, 26px);
        margin-right: 6px;
      }
    }

    .excel {
      &:hover {
        color: #000000;
        background-color: #F6F6F6;
      }
    }

    .zip {
      .icon {
        @include icon(url("../../../assets/zip.png"), 26px, 26px);
      }

      &:hover {
        color: #000000;
        background-color: #F6F6F6;
      }
    }
  }

  .info {
    font-size: 15px;
    margin-bottom: 10px;

    .mr-28 {
      display: inline-block;
      margin-right: 28px;
    }
  }

  .flex-end {
    @include flex(space-between, flex-end);
    font-size: 15px;

    .date {
      color: #999999;
    }

    .num {
      display: inline-block;
      color: #FF3C30;
      margin: 0 4px;
    }

    .check-btn {
      display: inline-block;
      margin-left: 10px;
      color: #309AF2;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.h-0 {
  height: 0;
}

.container {
  background-color: #fff;

  .menu {
    @include flex(flex-start, flex-end);
    height: 54px;
    padding-bottom: 4px;
    //border-bottom: 1px solid #E5E5E5;
    margin-left: 28px;
    margin-right: 28px;
    font-size: 15px;

    .list {
      margin-right: 28px;
      cursor: pointer;
      color: #999999;

      .line {
        background-color: transparent;
        width: 12px;
        height: 4px;
        margin: 7px auto 0;
        border-radius: 2px;
      }
    }

    .active {
      font-weight: bold;
      color: #333333;

      .line {
        background-color: #309AF2;
      }
    }
  }

  .content {
    height: calc(100vh - 344px);
    overflow-y: auto;
    padding-bottom: 10px;
    .student-list{
      padding-left: 28px;
      padding-right: 11px;
      //margin: 0 28px;
    }
    .dub-hoemwork-detail{
      padding: 28px;
      &>video{
        border-radius: 16px;
      }
      .hoemwork-info{
        margin-top: 28px;
        display: flex;
        .dub-sentence{
          min-width: 347px;
          max-width: 347px;
          padding-right: 28px;
          border-right: 1px solid #F6F6F6;
          .sentence-item{
            padding: 20px 28px;
            text-align: center;
            &+.sentence-item{
              border-top: 1px solid #F1F1F1;
            }
            .sentence-original{
              color: #333333;
              font-size: 18px;
              line-height: 25px;
              font-weight: bold;
            }
            .sentence-translated{
              margin-top: 14px;
              color: #999999;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
        .dub-response-records{
          margin-left: 28px;
          width: 639px;
          //padding: 14px 0;
          height: fit-content;
          border-radius: 16px;
          background-color: #F6F6F6;
          .record-header,.record-row{
            display: flex;
            margin: 28px 0;
            .row-sn,.row-student,.row-score{
              min-width: 213px;
              max-width: 213px;
              text-align: center;
            }
          }
          .record-header{
            .row-sn,.row-student,.row-score{
              color: #999999;
              font-size: 16px;
              line-height: 22px;
            }
          }
          .record-row{
            .row-sn,.row-student,.row-score{
              color: #333333;
              font-size: 18px;
              line-height: 25px;
            }
            .row-student{
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .row-score{
              display: flex;
              align-items: center;
              justify-content: center;
              .row-score-value{
                &.success {
                  color: #11BF69;
                }
                &.waning {
                  color: #FFA800;
                }
                &.error {
                  color: #F13A3A;
                }
              }
              .row-btn-play{
                margin-left: 10px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .h-212 {
    height: calc(100vh - 212px);
    animation-name: example;
    animation-duration: 0.6s;
    @keyframes example {
      from {
        height: calc(100vh - 344px);
      }
      to {
        height: calc(100vh - 212px);
      }
    }
  }

  .iframe {
    width: 100%;
    height: calc(100vh - 360px);
  }

  .h-188 {
    height: calc(100vh - 188px);
  }
}

.batch-list {
  height: 41px;
  line-height: 41px;
  @include font(15px, #333, center);
  cursor: pointer;

  &:hover {
    background-color: #F6F6F6;
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-15 {
  margin-right: 15px;
}
</style>
